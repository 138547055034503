import { getResidenceUser, deleteResidence, getReservationsUser,getReservationsAdmin, getResidenceFavoris, postReservation, postFavoris, deleteFavoris, postLogin, postInscription, postAgent, postQuartier, getQuartier, getQuartier2, getAvis, postAvis } from './api';

export default {
  install(app) {
    app.config.globalProperties.$api = {
      getResidenceUser,
      deleteResidence,
      getReservationsUser,
      getReservationsAdmin,
      getResidenceFavoris,
      postReservation,
      postFavoris,
      deleteFavoris,
      postLogin,
      postInscription,
      postAgent,
      postQuartier,
      getQuartier,
      getQuartier2,
      getAvis,
      postAvis,
      // Ajoutez d'autres méthodes API ici
    };
  },
};