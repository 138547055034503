<template>
	<div class="home">
		<div class="accueil_mobile_searchbar_component">
			<div class="navbarr">
				<div class="search-bar" @click="openActionSheet()">
					<i class="icon">&#x1F50D;</i>
					<input
					id="customInput"
					type="text"
					placeholder="Destination"
					:value="valeurRecherche"
					disabled
					/>
				</div>
			</div>
	  	</div>
  
	  	<transition name="slide-up">
			<div class="actionsheet" v-if="isOpen">
		  		<div class="actionsheet-overlay" @click="closeActionSheet()"></div>
  
		  			<div class="actionsheet-content">
						<div class="box">
							<div class="page-header">
								<p class="title-detail">Recherche</p>
								<button class="delete" aria-label="close" @click="closeActionSheet()"></button>
							</div>
  
							<div class="field">
								<div class="control" style="margin: 0px -12px;">
								<div class="select is-fullwidth">
									<select v-model="biens.rtype" placeholder="Abidjan">
										<option value="0">
											Tout type
										</option>
										<option v-for="item in listZone" :key="item.libelle">
											{{ item.libelle }}
										</option>
									</select>
								</div>
								</div>
							</div>

							<div class="field">
								<div class="columns is-mobile">
									<div class="column" style="padding: 0px">
										<div class="field">
											<div class="control" style="margin: 0px -12px;">
												<div class="select is-fullwidth" style="width: 87%">
													<select v-model="biens.rville" placeholder="Abidjan">
														<option value="0">
															Ville
														</option>
														<option v-for="(ville, index) in uniqueCities" :key="index" :value="ville">
															{{ ville }}
														  </option>
													</select>
												</div>
											</div>
										</div>
									</div>
									<div class="column" style="padding: 0px">
										<div class="field">
											<div class="control" style="margin: 0px -12px;">
												<div class="select is-fullwidth" style="width: 87%">
													<select v-model="biens.rquartier" placeholder="Abidjan">
														<option value="0">
															Quartier
														</option>
														<option v-for="(item, index) in filteredQuartiers" :key="index" :value="item.quartier">
															{{ item.quartier }}
														</option>
													</select>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<hr>
							<div class="field">
								<div class="columns is-mobile">
									<div class="column" style="padding: 0px">
										<div class="field">
										<div class="control">
											<input
											v-model="rechercheInfos.du"
											type="date"
											class="input"
											placeholder="Date de début"
											/>
										</div>
										</div>
									</div>
									<div class="column" style="padding: 0px">
										<div class="field">
											<div class="control">
												<input
												v-model="rechercheInfos.au"
												type="date"
												class="input"
												placeholder="Date de fin"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
  
							<div class="field">
								<div class="columns is-mobile" style="margin: 0px -12px">
									<div class="column is-half" style="padding: 0px">
									<el-popover placement="bottom" :width="175" trigger="click">
										<template #reference>
										<el-input
											v-model="rechercheInfos.adulte"
											style="max-width: 600px"
											size="large"
											placeholder="Saisir le nombre d'adultes"
										>
											<template #append>Adulte</template>
										</el-input>
										</template>
										<el-input-number v-model="rechercheInfos.adulte" :min="1" :max="10" @change="handleChange" />
									</el-popover>
									</div>
									<div class="column is-half" style="padding: 0px;">
									<el-popover placement="bottom" :width="175" trigger="click">
										<template #reference>
										<el-input
											v-model="rechercheInfos.enfant"
											style="max-width: 600px"
											size="large"
											placeholder="Saisir le nombre d'enfants"
										>
											<template #append>Enfant</template>
										</el-input>
										</template>
										<el-input-number v-model="rechercheInfos.enfant" :min="1" :max="10" @change="handleChange" />
									</el-popover>
									</div>
								</div>
							</div>
  
							<div class="field">
								<div class="control" style="margin: 0px -12px;">
								<button style="width: 100%" class="button is-primary" @click="ListeFiltre2()">Rechercher</button>
								</div>
							</div>
						</div>
		  			</div>
			</div>
	  	</transition>
	</div>
  </template>
  
  <script>
  import { ElLoading } from 'element-plus'
  export default {
	name: 'HomeView',
	computed: {
		rechercheInfos() {
			return this.$store.state.rechercheInfos;
		},
		uniqueCities() {
			const unique = new Set();
			return this.datasQuartier
				.map(item => item.ville)
				.filter(ville => {
				if (unique.has(ville)) {
					return false;
				} else {
					unique.add(ville);
					return true;
				}
			});
		},
		biens() {
			return this.$store.state.biens;
		},
		filteredQuartiers() {
			return this.datasQuartier.filter(item => item.ville === this.biens.rville);
		}
	},
	data() {
	  return {
		isOpen: false,
		value1: [1, 80],
		filters: {
        piscine: '',
        restaurant: '',
        bordureMer: '',
        evenement: '',
        internet: '',
      	},
		datasQuartier: [],
		page: 1,
		datas: [],
		datas2: [],
		selectedDate: [null, null],
		destination: 'Abidjan',
		adulte: 1,
		enfant: 1,
		Rville: '0',
		Rquartier: '0',
		Rtype: '0',
		valeurRecherche: '',
		selectedOption: '',
		dateDebut: '',
		dateFin: '',
		listZone: [
			{libelle: 'Studio'},
			{libelle: '2 Pièces'},
			{libelle: '3 Pièces'},
			{libelle: '4 Pièces'},
			{libelle: 'PLUS'}
		]
	  };
	},
	methods: {
	  searchData() {
		const info = {
			destination: this.rechercheInfos.destination,
			du: this.rechercheInfos.du,
			au: this.rechercheInfos.au,
			adulte: this.rechercheInfos.adulte,
			enfant: this.rechercheInfos.enfant,
		}
		this.$store.commit('setRechercheInfos', info);
	  },
	  fetchQuartier() {
        this.$api.getQuartier2()
          .then(response => {
            this.datasQuartier = response.data.data;
          })
          .catch(error => {
            console.error('Erreur de requête:', error);
          });
      },
	  openActionSheet() {
		this.isOpen = true;
		document.body.style.overflow = 'hidden';
	  },
	  closeActionSheet() {
		this.isOpen = false;
		document.body.style.overflow = '';
	  },
	  closeActionSheet2() {
		const startDate = new Date(this.dateDebut);
		const endDate = new Date(this.dateFin);
  
		if (startDate > endDate) {
		  alert("La date de fin doit être ultérieure à la date de début.");
		  return;
		}
  
		this.valeurRecherche = `${this.rechercheInfos.destination}, ${startDate.toLocaleDateString()} à ${endDate.toLocaleDateString()}, ${this.adulte} adultes, Option: ${this.selectedOption}`;
		this.closeActionSheet();
	  },
	  ListeFiltre2() {
		let dataSearch = {
			ville: this.biens.rville,
			commune: this.biens.rquartier,
			type: this.biens.rtype,
		};
		this.$store.commit('setBiensSearch', dataSearch);
		const loading = ElLoading.service({
            lock: true,
            text: 'Loading...',
        });
      const postData = {
        prix_min: this.value1[0] * 5000,
        prix_max: this.value1[1] * 5000,
        type: this.biens.rtype,
        ville: this.biens.rville,
        parpage: 10,
        commune: this.biens.rquartier,
        piscine: this.filters.piscine ? 1 : 0,
        restaurant: this.filters.restaurant ? 1 : 0,
        bordureMer: this.filters.bordureMer ? 1 : 0,
        evenement: this.filters.evenement ? 1 : 0,
        internet: this.filters.internet ? 1 : 0
      };

      if (postData.prix === 'Tarif') {
        postData.prix = '0';
      }

      if (postData.type === 'Types') {
        postData.type = '0';
      }
	  const randomId = Math.floor(Math.random() * 1000000);
      this.$axios.post('/web_liste_appart.php?page='+ this.page +'&id='+ randomId, postData)
        .then(response => {
          this.searchOk = true;
		  let data = {
            datas: response.data.data,
            total_row: response.data.total_rows,
            total_pages: response.data.total_pages,
			rtype: this.biens.rtype,
            rville: this.biens.rville,
            rquartier: this.biens.rquartier,
            rpage: this.biens.rpage
          }
          this.$store.commit('setDatas', data);
        })
        .catch(error => {
          console.error('Erreur de requête:', error);
        });
		loading.close();
		this.closeActionSheet();
    },
	},
	mounted() {
	  // Set dateDebut to tomorrow's date
	  this.fetchQuartier();
	  const tomorrow = new Date();
	  tomorrow.setDate(tomorrow.getDate() + 1);
	  this.rechercheInfos.du = tomorrow.toISOString().substr(0, 10);
  
	  // Set dateFin to the day after tomorrow
	  const dayAfterTomorrow = new Date();
	  dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
	  this.rechercheInfos.au = dayAfterTomorrow.toISOString().substr(0, 10);
	}
  };
  </script>
  
  <style scoped>
  @import url('https://cdn.jsdelivr.net/npm/bulma@0.9.3/css/bulma.min.css');
  
  .home {
	padding: 20px;
  }
  
  .search-bar {
	display: flex;
	align-items: center;
	background-color: #f0f0f0;
	border-radius: 20px;
	padding: 1px 15px;
  }
  
  .search-bar input {
	border: none;
	outline: none;
	background: none;
	flex: 1;
	padding: 12px;
	font-size: 16px;
	pointer-events: none;
  }
  
  .search-bar .icon {
	margin-right: 10px;
	color: #888;
  }
  
  .actionsheet {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.5);
	z-index: 1500;
  }
  
  .actionsheet-overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #00000024;
  }
  
  .actionsheet-content {
	position: relative;
	background: #fff;
	border-radius: 10px;
	width: 90%;
	max-width: 600px;
	z-index: 1510;
  }
  
  .page-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
  }
  </style>
  